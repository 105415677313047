.upcoming-events {
  h2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .swiper-slide {
    height: auto;
  }
}
