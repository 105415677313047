$color1: rgb(249, 213, 67);
$color2: rgb(56, 49, 38);
$color3: rgb(237, 237, 237);
$color1-opacity-50: rgba(249, 212, 67, 0.5);
$color2-opacity-50: rgba(56, 49, 38, 0.5);
$color2-opacity-70: rgba(56, 49, 38, 0.7);


$primary: $color1;
$secondary: $color2;
$font-family-sans-serif: 'Noto Sans', sans-serif;

// SWEET ALERT
$swal2-confirm-button-background-color: $color1;