@use "../../styles/variables" as v;

.featured-courses {
  background-color: v.$color3;
  padding: 100px 0;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }


}
