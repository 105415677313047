@use "../../styles/mixins" as m;
@use "../../styles/variables" as v;

.instructor-card {
  overflow: hidden;

  img {
    @include m.transition(transform 0.5s);
    &:hover {
      @include m.transform(scale(1.3) rotate(5deg));

      + .card-title{
        @include m.transform(translateY(0));
      }

    }
  }

  .card-title {
      position: absolute;
      bottom:0;
      left:0; right:0;
      background-color: v.$color1-opacity-50;
      text-align: center;
      margin:0;
      padding-top:10px;
      padding-bottom: 10px;
      pointer-events: none;

      @include m.transform(translateY(100%));
      @include m.transition(transform 0.5s);
  }

  h4,h5,h6{
    margin:0;
  }
}
