@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "./variables";
@import "bootstrap/scss/bootstrap";
@import "primereact/resources/themes/bootstrap4-light-blue/theme.css";


body{
    color: $color2;
}

a{
    text-decoration: none !important;
}


.p-multiselect{
    width:100%;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    margin-bottom:1rem
}