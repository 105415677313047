.loading-spinner{
    width:100%;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner-border{
        width: 10rem;
        height: 10rem;
        position: absolute;
        border-width: 0.5rem;
    
    }

    img{
        width:100px;
        height: 100px;
        opacity: 0.2;
}

}

